@import './normalize.css';

:root {
    --color-body: #A5A5A5;
    --color-headings: #F0F0F0;
    --color-buttons: #2A2A2A;
}

*, *::before, *::after {
    box-sizing: border-box;
}

::selection {
    background: var(--color-headings);
    color: #000;
}

html {
    font-size: 62.5%;
}

body {
    font-family: montserrat, Arial, Helvetica, sans-serif;
    color: var(--color-body);
    font-size: 2.2rem;
    line-height: 1.5;
    background: #161616;
}

h1,
h2,
h3 {
    color: var(--color-headings);
    margin-bottom: 1.1rem;
}

h1 {
    font-size: 6rem;
}

h2 {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
}

h3 {
    font-weight: 300;
    font-size: 2.4rem;
}

p {
    margin-top: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

@media screen and (min-width: 1024px) {
    body {
        font-size: 1.8rem;
    }
    h1 {
        font-size: 10rem;
    }
    h2 {
        font-size: 4rem;
    }
    h3 {
        font-size: 3rem;
    }
}

.container {
    max-width: 1200px;
    margin: 0 auto 6rem;
}

.block {
    padding: 0 2rem;

}

.block--dark {
    background: #000;
}

.block__header {
    text-align: center;
}

.block__heading {
    padding-top: 3rem;
}

.btn {
    background: var(--color-buttons);
    border: 0;
    color: var(--color-headings);
    cursor: pointer;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.4rem;
    margin: 1rem 0;
    outline: 0;
    padding: 2rem 2.5rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    transition: background .2s;
}

.btn:hover {
    background: #373737;
}

@media screen and (min-width: 1024px) {
    .btn {
        font-size: 1.5rem;
    }
}

.info {
    padding: 0;
}

.info__item {
    text-align: center;
    margin-bottom: 3rem;
    list-style: none;
}

.grid {
    display: grid;
}

@media screen and (min-width: 768px) {
    .grid {
        grid-template-columns: 1fr 1fr;
    }
    .info {
        column-gap: 5rem;
    }
}

.hero {
    margin-bottom: 10rem;
}

.hero__footer {
    color: var(--color-headings);
    font-size: 2.4rem;
}

.hero__heading {
    margin-bottom: 8rem;
}

.hero__summary {
    color: var(--color-headings);
    text-align: center;
    margin-top: 1rem;
    order: -1;
}

@media screen and (min-width: 768px) {
    .hero__summary {
        order: 1;
        text-align: end;
    }
}

.footer {
    text-align: center;
    padding: 6rem;
}

.footer__phone {
    margin: 0;
    color: var(--color-headings);
    font-size: 2rem;
}

.footer__email {
    margin: 0;
    text-decoration: underline;
    font-size: 1.8rem;
}

@media screen and (min-width: 1024px) {
    .footer__phone {
        font-size: 1.6rem;
    }
    .footer__email {
        font-size: 1.4rem;
    }
}

.nav {
    display: flex;
    gap: min(1rem);
    align-items: center;
    justify-content: space-between;
}

.nav__brand {
    text-transform: uppercase;
    font-weight: 800;
    color: var(--color-headings);
    font-size: 1.6rem;
    display: flex;
}

.nav__list {
    display: flex;
    padding: 0;
}

.nav__item {
    list-style: none;
    padding-right: 1.7rem;
    text-transform: uppercase;
    font-size: 1.6rem;
    transition: color .2s;
}

.nav__item:hover {
    color: var(--color-headings);
}

@media screen and (min-width: 768px) {
    .nav__brand {
        font-size: 2.5rem;
    }
    .nav__item {
        padding-right: 2.5rem;
        font-size: 1.8rem;
    }
}

.promotion {
    padding: 1rem;
    top: 0;
}

.promotion__text {
    color: var(--color-headings);
    text-align: center;
    font-size: 1.6rem;
    margin: 0;
}

.list {
    margin-top: 3rem;
}

.list--unticked {
    list-style: none;
    padding: 0;
}

.list__item {
    margin-bottom: 1.5rem;
}

.lists {
    margin-bottom: 5rem;
}

.ordered .list__item {
    justify-self: center;
}

@media screen and (min-width: 1024px) {
    .ordered .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.contact {
    height: 75vh;
}

.image {
    width: 100%;
    border-radius: 10px;
}